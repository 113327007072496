// FORM SUBMISSION
const form = document.getElementsByTagName('form')[0];
const name = document.querySelector<HTMLInputElement>('#name');
const fake = document.querySelector<HTMLInputElement>('#number');
const email = document.querySelector<HTMLInputElement>('#email');
const message = document.querySelector<HTMLInputElement>('#message');
const button = document.querySelector<HTMLButtonElement>('.estimate button');
const userMessage = document.querySelector<HTMLInputElement>('#user-message');

// Error messages
const ERROR_EMAIL_INVALID = 'Please enter a valid email address.';
const ERROR_EMAIL_NONE = 'Please provide an email before submitting.';
const ERROR_NAME_NONE = 'Please provide a name before submitting.';
const ERROR_FAKE_INPUT =
	'Please do not input any text in the phone number input field.';
const ERROR_MESSAGE_TOO_SHORT = 'Please write a longer message. Thank you!';

const disableElements = (disable: boolean) => {
	if (name) name.disabled = disable;
	if (fake) fake.disabled = disable;
	if (email) email.disabled = disable;
	if (message) message.disabled = disable;
	if (button) button.disabled = disable;
};

const clearInputs = () => {
	if (name) name.value = '';
	if (fake) fake.value = '';
	if (email) email.value = '';
	if (message) message.value = '';
};

const inputHasErrors = () => {
	if (fake && fake.value) {
		fake.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_FAKE_INPUT;
		return true;
	} else if (fake) {
		fake.dataset.invalid = "false";
	}
	if (name && !name.value) {
		name.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_NAME_NONE;
		return true;
	} else if (name) {
		name.dataset.invalid = "false";
	}

	// Spam filter for message
	let messageArray = message && message.value ? message.value.trim().split(' ') : [''];
	if (
		message &&
		message.value &&
		messageArray.length === 1 &&
		messageArray[0].toLowerCase() !== 'hello' &&
		messageArray[0].toLowerCase() !== 'hi' &&
		messageArray[0].toLowerCase() !== 'hey'
	) {
		message.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_MESSAGE_TOO_SHORT;
		return true;
	} else if (name) {
		name.dataset.invalid = "false";
	}

	if (email && !email.value) {
		email.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_EMAIL_INVALID;
		return true;
	}
	if (
		!email || !email.value.match(
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
		)
	) {
		if (email) email.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_EMAIL_INVALID;
		return true;
	} else {
		email.dataset.invalid = "false";
		if (userMessage) userMessage.textContent = '';
		return false;
	}
};

name?.addEventListener('change', (e) => {
	if (!(e.target as HTMLInputElement).value) {
		name.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_NAME_NONE;
	} else {
		if (userMessage && userMessage.textContent === ERROR_NAME_NONE) {
			userMessage.textContent = '';
		}
		name.dataset.invalid = "false";
	}
});

fake?.addEventListener('change', (e) => {
	if ((e.target as HTMLInputElement).value) {
		fake.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_FAKE_INPUT;
	} else {
		if (userMessage && userMessage.textContent === ERROR_FAKE_INPUT) {
			userMessage.textContent = '';
		}
		fake.dataset.invalid = "false";
	}
});

email?.addEventListener('change', (e) => {
	if (!(e.target as HTMLInputElement)?.value) {
		email.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_EMAIL_NONE;
	} else if (
		!email.value.match(
			/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
		)
	) {
		email.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_EMAIL_INVALID;
	} else {
		if (email) email.dataset.invalid = "false";
		if (
			userMessage &&
			(userMessage.textContent === ERROR_EMAIL_INVALID ||
				userMessage.textContent === ERROR_EMAIL_NONE)
		) {
			userMessage.textContent = '';
		}
	}
});

message?.addEventListener('change', () => {
	//Spam filter for message
	let messageArray = message.value ? message.value.trim().split(' ') : [''];
	if (
		message.value &&
		messageArray.length === 1 &&
		messageArray[0].toLowerCase() !== 'hello' &&
		messageArray[0].toLowerCase() !== 'hi' &&
		messageArray[0].toLowerCase() !== 'hey'
	) {
		message.dataset.invalid = "true";
		if (userMessage) userMessage.textContent = ERROR_MESSAGE_TOO_SHORT;
		return true;
	} else {
		if (name) name.dataset.invalid = "false";
		if (userMessage && (userMessage.textContent === ERROR_MESSAGE_TOO_SHORT)) {
			userMessage.textContent = '';
		}
	}
});

const onSubmit = async (e: SubmitEvent) => {
	e.preventDefault();

	//validate user input
	if (inputHasErrors()) {
		return;
	}
	//if no issues, disable inputs and send submission
	disableElements(true);
	if (userMessage) userMessage.textContent = 'Sending submission...';
	try {
		const res = await fetch("https://usebasin.com/f/e190135663e8", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: name?.value,
				email: email?.value,
				message: message?.value,
			}),
		})

		disableElements(false);
		if (res?.status !== 200) {
			if (userMessage) userMessage.textContent =
				'Sorry, there was an error processing your submission. Please try again later.';
		} else {
			clearInputs();
			if (userMessage) userMessage.textContent = 'Your submission was successfully received!';
		}
	} catch (e) {
		console.error(e);
		if (userMessage) userMessage.textContent =
			'Sorry, there was an error processing your submission. Please try again later.';
	}
};

form.addEventListener('submit', onSubmit);

export {}